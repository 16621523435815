<template>
  <v-layout
    class="main_desktop_cont"
    align-center
    justify-center
    column
    fill-height
    text-xs-center
  >
    <div class="login">
      <div class="login__inner block__content">
        <v-form v-model="valid" ref="form" @submit.prevent="login">
          <div class="login__logo">
            <img :src="require('../../assets/logo_with_text.svg')" />
          </div>
          <div class="login__logo-text">
            {{
              show == 1
                ? "Войти в аккаунт"
                : this.show == 3
                ? "Восстановить доступ в систему"
                : "Сменить пароль"
            }}
          </div>

          <login-default
            v-if="show == 1"
            :code.sync="code"
            :initialCode="initialCode"
            :userName.sync="userName"
            :userPassword.sync="userPassword"
            :modelError.sync="modelError"
            :userNameError="userNameError"
            :userPasswordError="userPasswordError"
            :required="rules.required"
            :cyrillicCheck="rules.cyrillicCheck"
            :spaceCheck="rules.spaceCheck"
            @reset="reset"
            @checkCapsLock="checkCapsLock"
          />

          <login-first
            v-if="show == 2"
            :userPasswordNew.sync="userPasswordNew"
            :userPasswordConfirmNew.sync="userPasswordConfirmNew"
            :required="rules.required"
            :spaceCheck="rules.spaceCheck"
            :equalToNew="rules.equalToNew"
            @reset="reset"
            @checkCapsLock="checkCapsLock"
          />

          <reset-password
            v-if="show == 3"
            :sendConfirmationCode="sendConfirmationCode"
            :userPhoneError="userPhoneError"
            :confirmationCodeError="confirmationCodeError"
            @reset="reset"
            @generateSmsCode="generateSmsCode"
            @sendSmsCode="sendSmsCode"
            @toggleResetPassword="toggleResetPassword"
            @toggleSendConfirmationCode="toggleSendConfirmationCode"
            ref="reset"
          />

          <div
            v-if="false"
            class="login_forgot-password text-left primary--text"
          >
            Проблемы со входом?
          </div>

          <v-alert
            v-if="error"
            border="top"
            color="error"
            dark
            :style="errorStyle"
          >
            {{ error }}
          </v-alert>

          <v-row
            v-show="show != 3"
            :class="[
              'flex-container',
              this.show === 1 ? 'justify-space-between' : 'justify-end',
            ]"
          >
            <a
              v-if="show == 1"
              class="text-decoration-underline"
              :style="'color: var(--v-blue-grey-base) !important;'"
              @click="toggleResetPassword"
              tabindex="8"
            >
              Не можете войти?
            </a>

            <v-btn
              class="btn-normale"
              type="submit"
              color="primary"
              large
              dark
              :loading="loading"
              tabindex="7"
            >
              {{ this.show == 1 ? "Войти" : "Сохранить и войти" }}
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
    <base-popup
      :headerHidden="true"
      v-model="dialog"
      :width="'auto'"
      v-bind="$attrs"
      style="min-width: 500px; min-height: 397px"
    >
      <v-container class="d-flex flex-column pt-5 pb-0">
        <div
          class="black--text mb-7"
          style="width: 100%; text-align: center; font-size: 18px"
        >
          Доступ в систему восстановлен
        </div>
        <div class="info-block mb-11 pl-10 pr-10">
          <div
            v-for="(item, index) in infoItems"
            :key="index"
            class="info-item"
          >
            <span>{{ item.label }}: </span>
            <span class="info-value">{{ item.value }}</span>
          </div>
        </div>
        <span
          class="black--text mt-n2 mb-8"
          style="width: 100%; text-align: center"
        >
          <div style="font-size: 16px">ВНИМАНИЕ!</div>
          Установлен временный пароль.<br />После входа в программу смените
          пароль.
        </span>
        <v-btn color="primary" large @click="dialog = !dialog">
          Продолжить
        </v-btn>
      </v-container>
    </base-popup>
  </v-layout>
</template>

<script>
import AuthService from "@/services/AuthService";
import BasePopup from "@/layouts/BasePopup.vue";

import loginDefault from "./components/loginDefault.vue";
import loginFirst from "./components/loginFirst.vue";
import resetPassword from "./components/resetPassword.vue";

export default {
  name: "view-Login",
  components: { loginDefault, loginFirst, resetPassword, BasePopup },
  data() {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    return {
      loading: false,
      dialog: false,
      valid: true,
      userName: user?.UserName,
      userPassword: "",
      userPasswordNew: null,
      userPasswordConfirmNew: null,
      sendConfirmationCode: false,
      code: null,
      initialCode: null,
      organizationName: null,
      show1: false,
      show2: false,
      show3: false,
      message: null,
      error: null,
      show: 1,
      checkbox: true,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        equalToNew: (value) =>
          value === this.userPasswordNew || "Пароли не совпадают",
        cyrillicCheck: (val) => {
          const regexp = /[а-яё]/i;
          return !regexp.test(val) || "Запрещен ввод кириллицы!";
        },
        spaceCheck: (val) => {
          const regexp = /[\s]/i;
          return !regexp.test(val) || "Запрещен ввод пробела!";
        },
      },
      modelError: null,
      userNameError: null,
      userPasswordError: null,
      userPasswordNewError: null,
      userPhoneError: null,
      confirmationCodeError: false,
    };
  },
  computed: {
    errorStyle() {
      return {
        "white-space": "pre-line",
        margin: this.show === 3 ? "16px 0 0 0" : "0 0 16px 0",
      };
    },
    infoItems() {
      return [
        { label: "Организация", value: this.organizationName },
        { label: "Код организации", value: this.initialCode },
        { label: "Логин", value: this.userName },
        { label: "Пароль (временный)", value: this.userPassword },
      ];
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("OwnerCode")) {
      setTimeout(() => {
        this.code = urlParams.get("OwnerCode");
        this.userName = urlParams.get("UserName");
        this.userPassword = urlParams.get("Password");

        this.initialCode = this.code;
      }, 1);
    }
  },

  methods: {
    reset() {
      this.modelError = null;
      this.userNameError = null;
      this.userPasswordError = null;
      this.userPasswordNewError = null;
      this.userPhoneError = null;
      this.confirmationCodeError = false;
    },
    async login() {
      this.reset();

      await new Promise((resolve) => {
        setTimeout(resolve, 1);
      });

      this.$refs.form.validate();
      if (!this.valid) return;

      this.error = null;
      this.message = null;
      this.loading = true;

      const pwd = this.userPassword;

      try {
        const { data } = await AuthService.login(
          this.userName,
          pwd,
          this.code,
          this.userPasswordNew
        );
        if (data?.User?.ChangePasswordOnFirstLogon === true) {
          this.loading = false;
          this.message = "Введите новый пароль";
          this.show = 2;
          this.$nextTick(() => {
            this.$refs.form.reset();
          });
          return;
        }

        document.location.href = "/";
      } catch (error) {
        this.loading = false;
        if (error.response.data.InternalErrorCode === 102) {
          this.userNameError = error.response.data.Message;
        } else if (error.response.data.InternalErrorCode === 104) {
          this.userPasswordError = error.response.data.Message;
        } else if (error.response.data.OwnerCode) {
          this.modelError = "Неверный код организации";
        } else {
          if (error.response.status === 422) {
            if (error.response.data.InternalErrorCode === 109) {
              this.error = `Уважаемый пользователь !\n
                            Вы использовали максимальное количество подключений для одного пользователя\n
                            Для входа в систему завершите работу на одном из используемых устройств`;
            } else if (error.response.data.InternalErrorCode === 108) {
              this.error = `Уважаемый пользователь !\n
                            Использовано максимальное количество подключений к системе, предусмотренных\n
                            вашей лицензией (${error.response.data.MaxUsersCount} пользователя(ей)). Попробуйте зайти через несколько минут.`;
            }
          } else this.error = error.response.data.Message;
        }
      }
      this.loading = false;
    },
    checkCapsLock(event, textError) {
      if (event?.getModifierState && event?.getModifierState("CapsLock")) {
        this[textError] = "Включен Caps Lock";
      } else {
        this[textError] = null;
      }
    },
    toggleResetPassword() {
      this.show === 3 ? (this.show = 1) : (this.show = 3);
    },
    async generateSmsCode(phoneNum, selectedOrganization) {
      try {
        this.sendConfirmationCode = true;
        await AuthService.getConfirmationCode(
          phoneNum.replace(/\D/g, ""),
          selectedOrganization
        );
      } catch (error) {
        this.sendConfirmationCode = false;
      }
    },
    async sendSmsCode(phoneNum, selectedOrganization, confirmationCode) {
      this.error = null;
      try {
        const response = await AuthService.sendConfirmNumber(
          phoneNum.replace(/\D/g, ""),
          selectedOrganization,
          confirmationCode
        );

        if (response.status === 200) {
          this.organizationName = this.$refs.reset.selectedOrganization.Name;
          this.initialCode = this.$refs.reset.selectedOrganization.Code;
          this.userName = response.data.UserName;
          this.userPassword = response.data.UserName;

          this.show = 1;
          this.sendConfirmationCode = false;
          this.dialog = true;
        }
      } catch {
        this.confirmationCodeError = true;
      }
    },
    toggleSendConfirmationCode() {
      this.sendConfirmationCode = !this.sendConfirmationCode;
    },
  },
};
</script>
<style lang="scss">
.theme--light.v-icon {
  color: var(--v-text-base);
}
</style>
<style>
.login__inner .v-text-field__slot label.v-label,
.login__inner .v-select__slot label.v-label {
  color: var(--v-text-lighten1) !important;
  font-size: 14px;
  font-weight: 700;
}
</style>

<style lang="sass">
.login
  .v-input:not(:last-child)
    margin-bottom: 8px
  &__inner
    padding: 40px 60px
    width: 500px
    text-align: center
.login__logo-text
  margin: 40px 0
  font-size: 20px
  line-height: 158%
  letter-spacing: -0.01em
  color: var(--v-text-base)

.login_forgot-password
  padding-left: 10px
  padding-top:10px
  font-weight: 500
  font-size: 14px
  line-height: 158%
  letter-spacing: -0.01em

.login__info
  padding: 0 0 30px 0
  font-size: 14px

.flex-container
  display: flex
  align-items: center
  margin: 0

.justify-space-between
  justify-content: space-between

.justify-end
  justify-content: flex-end
</style>
<style scoped>
.info-block {
  font-size: 16px;
}

.info-item {
  margin-bottom: 10px;
}

.info-value {
  color: black;
}
</style>
