<template>
  <div>
    <input-company
      v-model="localCode"
      :initialCode="initialCode"
      :modelError.sync="localModelError"
      :rules="[required]"
      @focus="reset"
    />

    <v-text-field
      v-model="localUserName"
      ref="login"
      :rules="[required, cyrillicCheck, spaceCheck]"
      label="Логин"
      placeholder=" "
      persistent-placeholder
      outlined
      required
      :error-messages="userNameError"
      @focus="reset"
      tabindex="3"
    >
    </v-text-field>
    <v-text-field
      v-model="localUserPassword"
      :rules="[required, spaceCheck]"
      @click:append="show1 = !show1"
      :type="show1 ? 'text' : 'password'"
      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
      label="Пароль"
      placeholder=" "
      persistent-placeholder
      outlined
      required
      :error-messages="userPasswordError"
      @keydown="checkCapsLock($event, 'userPasswordError')"
      @focus="reset"
      tabindex="4"
    >
    </v-text-field>

    <div class="login__info text-left">
      Используйте аккаунт Медэксперт, чтобы войти в систему.
    </div>
  </div>
</template>

<script>
import inputCompany from "./inputCompany.vue";

export default {
  components: { inputCompany },

  props: {
    code: { type: String, default: "" },
    initialCode: { type: String, default: "" },
    userName: { type: String, default: "" },
    userPassword: { type: String, default: "" },
    modelError: { type: String, default: "" },
    userNameError: { type: String, default: "" },
    userPasswordError: { type: String, default: "" },
    required: { type: Function, default: null },
    cyrillicCheck: { type: Function, default: null },
    spaceCheck: { type: Function, default: null },
  },
  data() {
    return {
      localCode: this.code,
      localUserName: this.userName,
      localUserPassword: this.userPassword,
      localModelError: this.modelError,

      show1: false,
    };
  },
  watch: {
    userName() {
      this.localUserName = this.userName;
    },
    userPassword() {
      this.localUserPassword = this.userPassword;
    },
    localCode(newVal) {
      this.$emit("update:code", newVal);
    },
    localUserName(newVal) {
      this.$emit("update:userName", newVal);
    },
    localUserPassword(newVal) {
      this.$emit("update:userPassword", newVal);
    },
    localModelError(newVal) {
      this.$emit("update:modelError", newVal);
    },
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    checkCapsLock(event, textError) {
      this.$emit("checkCapsLock", event, textError);
    },
  },
};
</script>
